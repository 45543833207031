import React from "react";
import ReactGA from "react-ga";

const IncorrectRoutes = ({ advisorId, meetingId }) => {
  React.useEffect(() => {
    ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS);
    ReactGA.event({
      category: "Error - client",
      action: `Advisor : ${advisorId} Meeting : ${meetingId}`
    });
  }, []);

  return (
    <React.Fragment>
      <p style={{ color: "white" }}> something went wrong</p>
    </React.Fragment>
  );
};
export default IncorrectRoutes;
